html {
  font-size: 62.5%;
  font-family: "Quicksand", sans-serif;
}

body {
  font-family: "Quicksand", sans-serif;
  font-size: 1rem;
  /* font-size: calc(1.3rem + 3.6vw); */
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
