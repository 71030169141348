@import "/src/resources/styles/color.scss";

.main {
  // height: calc(100vh - 7rem);
  height: max-content;
  background-color: $bg-color;
  // margin: 0 auto;
  padding: 6rem 0;
  display: flex;
  flex-direction: column;
  gap: 4.5rem;

  // @media screen and (max-width: 1000px) {
  //   height: max-content;
  // }

  .topContainer {
    display: flex;
    justify-content: center;

    .title {
      text-align: center;
      font-family: Quicksand;
      font-size: 4.375rem;
      font-style: normal;
      font-weight: 700;
      line-height: normal;

      @media screen and (max-width: 760px) {
        font-size: 3.5rem;
      }
    }
  }

  .imgContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 8rem;

    @media screen and (max-width: 760px) {
      margin: 1rem;

      img {
        max-width: 100% !important;
        max-height: 100% !important;
        object-fit: contain;
      }
    }

    img {
      max-width: 80%; // Ensure image doesn't exceed container width
      max-height: 100%; // Ensure image doesn't exceed container height
      object-fit: contain; // Maintain aspect ratio and fit inside the container
    }
  }

  //

  // .gridContainer {
  //   display: flex;
  //   flex-direction: column;
  //   justify-content: space-between;
  //   align-items: center;
  //   padding: 0 10rem;
  //   gap: 8rem;
  //   height: inherit;
  //   background-color: $bg-color;
  //   // justify-content: space-between;
  //   // align-items: center;
  //   // justify-content: center;

  //   @media screen and (max-width: 1000px) {
  //     // flex-direction: column;
  //     // align-items: center;
  //     gap: 2rem;
  //     padding: 0 8rem;
  //   }

  //   @media screen and (max-width: 800px) {
  //     // flex-direction: column;
  //     padding: 0 2rem;
  //     gap: 7rem;
  //   }

  //   .subTitle {
  //     font-size: 4.2rem;
  //     font-weight: 600;
  //     font-family: "Quicksand", sans-serif;
  //     color: $brand-pink-color;

  //     @media screen and (max-width: 1100px) {
  //       font-size: 3rem;
  //     }

  //     @media screen and (max-width: 800px) {
  //       font-size: 3.2rem;
  //       text-align: center;
  //     }
  //   }

  //   .largeTitle {
  //     font-size: 5rem;
  //     font-weight: 600;
  //     font-family: "Quicksand", sans-serif;
  //     color: $brand-pink-color;
  //     text-align: center;

  //     @media screen and (max-width: 1100px) {
  //       font-size: 3rem;
  //     }

  //     @media screen and (max-width: 800px) {
  //       font-size: 3.2rem;
  //       text-align: center;
  //       // fon
  //       // text-align: left;
  //     }
  //   }

  //   .bottomTitleContainer {
  //     display: flex;
  //     flex-direction: column;
  //     justify-content: space-between;
  //     justify-content: center;
  //     align-items: center;
  //     gap: 0.4rem;
  //     width: 100%;
  //     // flex-direction: column;

  //     .title {
  //       font-size: 2.8rem;
  //       font-weight: 700;
  //       font-family: "Quicksand", sans-serif;
  //       color: $brand-color;
  //       margin-top: auto;

  //       @media screen and (max-width: 1000px) {
  //         font-size: 3rem;
  //       }

  //       @media screen and (max-width: 800px) {
  //         font-size: 2.5rem;
  //         text-align: center;
  //       }
  //     }

  //     .arrowImgContainer {
  //       img {
  //         object-fit: contain;
  //       }
  //     }
  //   }

  //   .gridOne {
  //     display: flex;
  //     // flex: 1;
  //     gap: 10rem;
  //     align-items: center;
  //     justify-content: center;

  //     @media screen and (max-width: 1000px) {
  //       gap: 7rem;
  //     }

  //     .columns {
  //       width: 18rem;
  //       min-height: 90%;
  //       background-color: $brand-pink-color;
  //       border-radius: 3.5rem;

  //       @media screen and (max-width: 1000px) {
  //         // gap: 18rem;
  //         height: 50rem;
  //         width: 16rem;
  //       }

  //       @media screen and (max-width: 800px) {
  //         height: 30rem;
  //         width: 10rem;
  //       }
  //     }
  //   }

  //   .gridTwo {
  //     display: flex;
  //     flex-direction: column;
  //     justify-content: space-between;
  //     padding: 2.5rem 0 5rem 0;
  //     flex: 1;

  //     .textSection {
  //       display: flex;
  //       flex-direction: column;

  //       .title {
  //         font-size: 12rem;
  //         font-weight: 700;
  //         color: $brand-pink-color;

  //         @media screen and (max-width: 1100px) {
  //           font-size: 10rem;
  //           text-align: left;
  //         }

  //         @media screen and (max-width: 800px) {
  //           font-size: 8rem;
  //           text-align: left;
  //         }
  //       }

  //       .subtitle {
  //         font-size: 4rem;
  //         font-weight: 400;
  //         font-family: "Work Sans", sans-serif;
  //         color: $brand-pink-color;

  //         @media screen and (max-width: 1100px) {
  //           font-size: 3rem;
  //         }

  //         @media screen and (max-width: 800px) {
  //           font-size: 3rem;
  //           text-align: left;
  //         }
  //       }
  //     }

  //     .bottomTitleContainer {
  //       display: flex;
  //       justify-content: space-between;
  //       width: 100%;
  //       // flex-direction: column;

  //       .title {
  //         font-size: 3rem;
  //         font-weight: 700;
  //         font-family: "Quicksand", sans-serif;
  //         color: $brand-color;
  //         margin-top: auto;

  //         @media screen and (max-width: 1000px) {
  //           font-size: 3rem;
  //         }

  //         @media screen and (max-width: 800px) {
  //           font-size: 2.5rem;
  //           text-align: left;
  //         }
  //       }

  //       .arrowImgContainer {
  //         img {
  //           object-fit: contain;
  //         }
  //       }
  //     }
  //   }
  // }
}
