.inputContainer {
  display: flex;
  flex-direction: column;
  flex: 1;

  &.lg {
    .label {
      font-family: Quicksand;
      font-size: 1.875rem;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      letter-spacing: 0.01875rem;

      @media screen and (max-width: 760px) {
        font-size: 1.5rem;
      }
    }
  }

  .label {
    font-weight: 500;
    color: #666666;
    letter-spacing: 0.01em;
    font-size: 1.2rem;
  }
}
