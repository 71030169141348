@import "/src/resources/styles/color.scss";

.main {
  height: calc(100vh - 7rem);
  // margin: 0 auto;
  background-color: $brand-violet-2-color;
  padding: 6rem 0;
  display: flex;

  @media screen and (max-width: 1000px) {
    height: max-content;
  }
  .container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    width: 100%;

    @media screen and (max-width: 800px) {
      gap: 6rem;
    }

    .textContainer {
      display: flex;
      flex-wrap: wrap;
      font-size: 5rem;
      font-weight: 700;
      color: $bg-color;
      margin: 6rem 5rem 0 5rem;

      @media screen and (max-width: 1000px) {
        margin: 5rem 4rem 0 4rem;
        font-size: 5rem;
      }

      @media screen and (max-width: 800px) {
        font-size: 3.5rem;
        margin: 3rem 2rem 0 2rem;
      }
    }

    .carouselContainer {
      width: 100%;
      gap: 3rem;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      flex: 1;

      .buttonsContainer {
        display: flex;
        justify-content: flex-end;
        gap: 2rem;
        width: 100%;
        margin-right: 10rem;
        position: relative;
        z-index: 100;

        .btnIconContainer {
          border-radius: 50%;
          border: 1px solid $white;
          display: flex;
          align-items: center;
          justify-content: center;
          color: $white;
          font-size: 2rem;
          padding: 0.5rem;
          cursor: pointer;
        }
      }
    }
  }
}
