@import "/src/resources/styles/color.scss";

button {
  display: inline-block;
  border-radius: 3rem;
  border: none;
  outline: none;
  cursor: pointer;
  position: relative;

  &.buttonText {
    transition: all 0.2s;
  }

  &.loading .buttonText {
    visibility: hidden;
    opacity: 0;
  }

  &:disabled {
    opacity: 0.7;
    cursor: not-allowed;
  }
  &.loading::after {
    content: "";
    position: absolute;
    width: 14px;
    height: 14px;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    border: 3px solid transparent;
    border-radius: 50%;
    animation: button-loading-spinner 1s ease infinite;
  }

  @keyframes button-loading-spinner {
    from {
      transform: rotate(0turn);
    }

    to {
      transform: rotate(1turn);
    }
  }

  &.primary {
    background-color: $brand-color;
    color: $bg-color;

    &:disabled {
      cursor: not-allowed;
      opacity: 0.6;
    }

    &.loading::after {
      border-top-color: $white;
    }
  }

  &.secondary {
    background-color: $bg-color;
    color: $brand-color;

    &:hover {
      background-color: $brand-violet-2-color;
      color: #fff;
      border-radius: 3rem;
    }

    &.loading::after {
      border-top-color: $brand-color;
    }
  }

  &.outline {
    background-color: transparent;
    color: $brand-color;
    border: 1px solid $brand-color;
  }
}
