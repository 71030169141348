@import "/src/resources/styles/color.scss";

.confirmDialogContainer {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 3rem;
  gap: 1rem;

  .imgContainer {
    text-align: center;
  }

  .textContainer {
    text-align: center;
    font-size: 2rem;
    font-weight: 700;
    color: $brand-color;
  }
}

.dialogContainer {
  // margin: 0 4rem 4rem 4rem;
  display: flex;
  margin: 1.5rem;
  // flex-direction: column;
  // gap: 2.5rem;

  // @media screen and (max-width: 500px) {
  //   gap: 1.5rem;
  //   margin: 1.5rem;
  // }

  .closeBtnContainer {
    display: flex;
    justify-content: flex-end;

    .closeBtn {
      cursor: pointer;
      font-size: 2rem;
      color: #666666;
    }
  }

  .formMainContainer {
    display: flex;
    flex-direction: column;
    gap: 2.5rem;
    flex: 1;
    margin: 2rem;

    @media screen and (max-width: 500px) {
      gap: 1.5rem;
      // margin: 1.5rem;
      margin: 0.75rem;
    }

    .headerTitle {
      font-size: 2rem;
      font-weight: 700;
      color: $brand-color;
      letter-spacing: 0.01em;
    }

    .formSection {
      display: flex;
      flex-direction: column;
      gap: 2rem;

      @media screen and (max-width: 500px) {
        gap: 1rem;
      }

      .flexField {
        display: flex;
        gap: 3rem;
        @media screen and (max-width: 400px) {
          gap: 1.5rem;
        }

        @media screen and (max-width: 600px) {
          flex-direction: column;
        }

        .selectTextFieldContainer {
          flex: 1;

          .label {
            font-weight: 500;
            color: #666666;
            letter-spacing: 0.01em;
            font-size: 1.2rem;
          }
        }
      }

      .halfWidthContainer {
        display: flex;
        width: 49%;
        margin-bottom: 1.5rem;

        @media screen and (max-width: 600px) {
          width: 100%;
        }

        .selectTextFieldContainer {
          flex: 1;

          .label {
            font-weight: 500;
            color: #666666;
            letter-spacing: 0.01em;
            font-size: 1.2rem;
          }
        }
      }

      .btnMainContainer {
        margin-top: 1rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .btnContainer {
          font-size: 1.5rem;
          font-weight: 700;
          padding: 1rem 2.5rem;
        }

        .formError {
          font-size: 1.5rem;
          color: $orange-loss;
        }
      }
    }
  }
}
