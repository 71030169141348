@import "/src/resources/styles/color.scss";

.formRootContainer {
  height: 100%;
  background-color: $white;
  border-radius: 2rem;
  padding: 3rem;
  display: flex;
  flex-direction: column;
  gap: 3rem;

  .headerContainer {
    display: flex;
    justify-content: space-between;

    .title {
      font-size: 3rem;
      font-style: normal;
      font-weight: 700;
      line-height: normal;

      @media screen and (max-width: 760px) {
        font-size: 2rem;
      }
    }

    .iconContainer {
      display: flex;
      cursor: pointer;
      align-items: center;
      svg {
        width: 3rem;
        height: 3rem;

        @media screen and (max-width: 760px) {
          width: 2.5rem;
          height: 2.5rem;
        }
      }
    }
  }

  form {
    height: 100%;
  }

  .formSection {
    display: flex;
    flex-direction: column;
    height: 100%;
    gap: 3rem;

    .label {
      font-size: 1.875rem;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      letter-spacing: 0.01875rem;
      color: $secondary-color-2;

      @media screen and (max-width: 760px) {
        font-size: 1.5rem;
      }
    }

    .selectFieldRootContainer {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;

      .selectFieldContainer {
        display: flex;
        flex-direction: column;
        gap: 0.25rem;

        .errorText {
          font-size: 1rem;
          color: $orange-loss;
        }
      }
    }

    .flexField {
      display: flex;
      gap: 3rem;

      @media screen and (max-width: 760px) {
        flex-direction: column;
      }

      &.alignBottom {
        margin-top: auto;

        @media screen and (max-width: 760px) {
          gap: 1.5rem;
        }
      }

      .btnRootContainer {
        display: flex;
        flex-grow: 1;

        > button {
          width: 100%;
        }

        .btnText {
          font-family: Quicksand;
          font-size: 2rem;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
          text-align: center;
          padding: 1.5rem 4rem;

          @media screen and (max-width: 760px) {
            font-size: 1.5rem;
          }
        }
      }
    }
  }
}
