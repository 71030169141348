@import "/src/resources/styles/color.scss";

.header {
  position: sticky;
  top: 0;
  height: 7rem;
  z-index: 100;
  background-color: $brand-pink-color;
  // background-color: $white;
  // border-bottom: 1px solid $white;
  padding: 0 6em;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition-property: all;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 500ms;
  // border-bottom: 1px solid $brand-pink-color;

  &.hide {
    top: -7rem;
  }

  @media screen and (max-width: 1000px) {
    padding: 0 5rem;
  }

  @media screen and (max-width: 800px) {
    padding: 0 2rem;
  }

  .brandContainer {
    display: flex;
    align-items: center;
    width: 24rem;
    height: 4rem;
    cursor: pointer;

    @media screen and (max-width: 1000px) {
      width: 20rem;
    }

    @media screen and (max-width: 800px) {
      width: 15rem;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  .rightContainer {
    display: flex;
    gap: 3rem;
    align-items: center;
    // padding: 2.9rem 0;

    @media screen and (max-width: 1000px) {
      gap: 2rem;
    }

    @media screen and (max-width: 800px) {
      gap: 1rem;
    }

    .btnContainer {
      display: flex;
      justify-content: center;
      align-items: center;
      //   font-size: 1.8rem;
      padding: 1rem 2.7rem;
      font-weight: 700;

      @media screen and (max-width: 1000px) {
        padding: 0.8rem 1.5rem;
      }

      @media screen and (max-width: 800px) {
        padding: 0.8rem 1.5rem;
      }
    }
  }
}
