@import "/src/resources/styles/color.scss";

.cardContainer {
  display: flex;
  padding: 5.5rem 0;
  background-color: $brand-color;
  border-radius: 75px;
  justify-content: left;
  align-items: center;
  gap: 2rem;
  min-height: 18rem;
  max-height: 18rem;

  // @media screen and (max-width: 800px) {
  //   // gap: 1rem;
  //   gap: 0;
  //   justify-content: space-around;
  // }

  .imgContainer {
    width: 22rem;
    height: 14rem;

    @media screen and (max-width: 1000px) {
      width: 20rem;
      height: 13rem;
    }

    @media screen and (max-width: 800px) {
      width: 18rem;
      height: 10rem;
    }

    img {
      width: 100%;
      height: 100;
      object-fit: contain;
    }
  }

  .textContainer {
    display: flex;
    padding: 0 3rem;
    flex-direction: column;
    gap: 1rem;

    .title {
      font-size: 3rem;
      font-weight: 700;
      color: $bg-color;

      @media screen and (max-width: 1000px) {
        font-size: 2.5rem;
      }

      // @media screen and (max-width: 800px) {
      //   font-size: 1.5rem;
      // }
    }

    hr {
      width: 20%;
      height: 2px;
      margin-left: 0;
      border: none;
      border-radius: 2px;
      background-color: $bg-color;
    }

    .subTitle {
      font-family: "Work Sans", sans-serif;
      font-size: 2rem;
      color: $bg-color;
      font-weight: 300;

      @media screen and (max-width: 1000px) {
        font-size: 1.5rem;
      }

      // @media screen and (max-width: 800px) {
      //   font-size: 1rem;
      // }
    }
  }
}
