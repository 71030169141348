@import "/src/resources/styles/color.scss";

.container {
  display: flex;
  flex-direction: column;
  gap: 1.5rem;

  .title {
    font-size: 2.5rem;
    font-weight: 700;
  }

  .paraContainer {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;

    .paraTitle {
      font-size: 1.8rem;
      font-weight: 700;
      margin-top: 0.3rem;
    }

    .subHeading {
      font-size: 1.5rem;
      font-weight: 700;
      margin-top: 0.3rem;
    }

    .paragraph {
      display: flex;
      flex-wrap: wrap;
      font-size: 1.5rem;
      font-weight: 500;
      line-height: 3rem;
      justify-content: stretch;

      a {
        color: #000;
        margin-left: 0.5rem;
      }
    }
  }
}
