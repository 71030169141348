$brand-color: #100828;
$white: #fff;
$brand-pink-color: #f73e77;
$bg-color: #f0eff4;
$brand-violet-color: #965ae5;
$brand-violet-2-color: #b54ad1;
$brand-light-pink-color: #f982b3;
$orange-loss: #eb5757;
$secondary-color-2: #56595c;
$secondary-color-3: #46484d;

$text-black-21: #212121;
