@import "/src/resources/styles/color.scss";

:root {
  --circle-size: clamp(1.5rem, 5vw, 3rem);
  --spacing: clamp(0.25rem, 2vw, 0.5rem);
}

.main {
  height: calc(100vh - 7rem);
  background-color: $brand-color;
  margin: 0 auto;
  padding: 6rem 0;
  display: flex;

  @media screen and (max-width: 1000px) {
    height: max-content;
  }

  .container {
    display: flex;
    flex: 1;
    flex-direction: column;
    gap: 1rem;
    width: 100%;
    margin: 0 auto;
    justify-content: center;
    align-items: center;
    // margin: 6rem auto;
    margin-top: 6rem;
    margin-left: auto;
    margin-right: auto;

    @media screen and (max-width: 800px) {
      gap: 3rem;
    }

    .textContainer {
      display: flex;
      flex-wrap: wrap;
      font-size: 5.5rem;
      font-weight: 700;
      color: $bg-color;

      @media screen and (max-width: 1000px) {
        font-size: 5rem;
        text-align: center;
      }

      @media screen and (max-width: 800px) {
        font-size: 3.2rem;
        text-align: center;
      }
    }

    .stepperContainer {
      flex: 1;
      display: flex;
      flex-direction: column;
      gap: 4rem;

      // align-items: center;
      .stepper {
        margin-top: auto;
        .stepLabel {
          color: $bg-color;
          font-size: 3rem;
          font-weight: 300;
          font-family: "Work Sans", sans-serif;

          b {
            font-weight: 600;
          }

          @media screen and (max-width: 800px) {
            font-size: 2rem;
            // text-align: center;
          }
        }
      }

      .bottomText {
        margin-top: auto;
        font-weight: 400;
        font-family: "Work Sans", sans-serif;
        color: $brand-pink-color;
        text-align: center;
        // margin-bottom: -40px;

        font-size: 3rem;

        @media screen and (max-width: 1000px) {
          font-size: 2.5rem;
        }
      }
    }
  }
}

.labelContainer {
  width: 11rem;
  height: 11rem;

  @media screen and (max-width: 800px) {
    width: 8rem;
    height: 8rem;
  }

  .stepLabels {
    width: 10rem;
    height: 10rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    font-size: 3rem;
    font-weight: 700;
    background-color: $brand-pink-color;

    @media screen and (max-width: 800px) {
      width: 7rem;
      height: 7rem;
      font-size: 2rem;
    }
  }
}
