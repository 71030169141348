@import "/src/resources/styles/color.scss";

.heroSection {
  height: calc(100vh - 7rem);
  background-color: $brand-color;
  font-weight: 700;
  // gap: 5.8rem;
  gap: 0;
  display: flex;
  // flex-direction: column;
  // justify-content: center;
  padding-bottom: 6rem;
  padding: 0 5rem 6rem 5rem;
  overflow: hidden;

  @media screen and (max-width: 760px) {
    padding: 1.75rem 1.35rem;
    height: max-content;
  }

  &.showForm {
    gap: 4.12rem;

    @media screen and (max-width: 760px) {
      gap: 0;
    }

    .leftContainer {
      width: 50%;

      .textContainer {
        align-items: flex-start;
        .title {
          text-align: left;
        }

        .subtitle {
          text-align: left;
        }
      }

      @media screen and (max-width: 760px) {
        transform: translateX(-110%);
        width: 0; // Set the width to 0 when the form is shown
        visibility: hidden;
        transition: transform 0.3s ease-in-out, width 0.3s ease-in-out; // Ensure smooth transition
      }
    }
  }

  @media screen and (max-width: 1000px) {
    // height: max-content;
    // height: calc(100vh - 7rem);
  }

  .leftContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    gap: 3rem;
    transition: width 0.1s ease-in-out;

    @media screen and (max-width: 760px) {
      justify-content: flex-start;
    }

    // &.showForm {
    //   width: 50%;

    //   .textContainer {
    //     align-items: flex-start;
    //     .title {
    //       text-align: left;
    //     }
    //   }
    // }

    .textContainer {
      // margin: 0 5rem;
      display: flex;
      flex-direction: column;
      gap: 3rem;
      align-items: center;
      // gap: 5rem;

      @media screen and (max-width: 760px) {
        gap: 1rem;
        margin: 2rem 2rem;
      }

      .title {
        font-size: 7rem;
        line-height: 8rem;
        color: $bg-color;
        text-align: center;
        margin: 0;

        @media screen and (max-width: 760px) {
          font-size: 4rem;
          line-height: 6rem;
        }
      }

      .subtitle {
        font-size: 4.8rem;
        color: $brand-pink-color;
        text-align: center;

        @media screen and (max-width: 760px) {
          font-size: 3rem;
        }
      }
    }

    .btnRootContainer {
      display: flex;
      flex-direction: column;
      gap: 0.75rem;
      width: max-content;

      &.hidden {
        // visibility: hidden;
        display: none;
      }

      .btnContainer {
        display: flex;
        padding: 1.5rem 4rem;
        align-items: center;
        gap: 2rem;

        .btnText {
          font-size: 2rem;
          font-family: Quicksand;
          font-size: 2rem;
          font-style: normal;
          font-weight: 700;

          @media screen and (max-width: 760px) {
            font-size: 1.5rem;
          }
        }

        .iconContainer {
          display: flex;
          align-items: center;
          svg {
            width: 2rem;
            height: 2rem;

            @media screen and (max-width: 760px) {
              width: 1.5rem;
              height: 1.5rem;
            }
          }
        }
      }

      .btnFooterText {
        font-family: Quicksand;
        font-size: 1.875rem;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        text-align: center;
        color: $white;

        @media screen and (max-width: 760px) {
          font-size: 1.5rem;
        }
      }
    }
  }

  .rightContainer {
    display: flex;
    flex-direction: column;
    width: 0%;
    padding: 3.5rem 0rem 3.5rem 0;
    flex-direction: column;
    justify-content: center;
    position: relative;
    transform: translateX(110%); // Initially hidden off-screen
    // transition: transform 0.3s ease-out; // Smooth transition
    transition: transform 0.3s ease-in-out; // Add width to transition properties
    visibility: hidden;

    &.showForm {
      transform: translateX(0); // When form is visible
      width: 50%;
      visibility: visible;

      @media screen and (max-width: 760px) {
        width: 100%;
      }
    }
  }
}
