@import "/src/resources/styles/color.scss";

.main {
  display: flex;
  flex-direction: column;
  background-color: $bg-color;
  padding-bottom: 5rem;

  @media screen and (max-width: 1100px) {
    padding-bottom: 3rem;
  }

  @media screen and (max-width: 800px) {
    padding-bottom: 2rem;
  }
  // gap: 1.5rem;

  .pointsContainer {
    flex: 1;
    margin: 1rem 6rem 0 6rem;
    display: flex;
    flex-direction: column;
    gap: 2rem;

    @media screen and (max-width: 1100px) {
      margin: 1rem 4rem 0 4rem;
    }

    @media screen and (max-width: 800px) {
      margin: 1rem 2rem 0 2rem;
    }
  }

  .container {
    flex: 1;
    margin: 4rem 6rem 0 6rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;

    @media screen and (max-width: 1100px) {
      margin: 3rem 4rem 0 4rem;
    }

    @media screen and (max-width: 800px) {
      margin: 2rem 2rem 0 2rem;
    }

    // @media screen and (max-width: 1100px) {
    //     font-size: 2.5rem;
    //   }

    //   @media screen and (max-width: 800px) {
    //     font-size: 2rem;
    //   }

    .headingTitle {
      font-size: 700;
      font-size: 5rem;
      color: $brand-pink-color;
      margin: 0;

      @media screen and (max-width: 1100px) {
        font-size: 4rem;
      }

      @media screen and (max-width: 800px) {
        font-size: 3rem;
      }
    }
  }
}

a {
  color: -webkit-link;
}