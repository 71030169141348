@import "/src/resources/styles/color.scss";

.container {
  display: flex;
  flex-direction: column;
  // gap: 0.5rem;
  margin-top: 0.3rem;

  .title {
    font-size: 1.8rem;
    font-weight: 700;
  }

  .subHeading {
    font-size: 1.5rem;
    font-weight: 700;
  }

  .listContainer {
    .listTitle {
      font-size: 1.5rem;
      font-weight: 500;
      line-height: 3rem;
    }

    .list {
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
      .listItem {
        font-size: 1.5rem;
        font-weight: 500;
        line-height: 3rem;
      }
    }
  }

  // .paraContainer {
  //   display: flex;
  //   flex-direction: column;
  //   gap: 2rem;

  //   .paragraph {
  //     display: flex;
  //     flex-wrap: wrap;
  //     font-size: 1.5rem;
  //     font-weight: 500;
  //     line-height: 3rem;
  //     justify-content: stretch;

  //   }
  // }
}
