@import "/src/resources/styles/color.scss";

.cardContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: $white;
  // border: 1px solid #000000;
  border-radius: 45px;
  box-shadow: 0px 4px 8px 0px rgba(100, 100, 100, 0.22);
  // width: 50rem;
  // height: 47rem;
  // max-width: 5rem;
  // max-height: 4.7rem;

  padding: 4rem 8rem;
  gap: 2rem;
  // width: 230px;
  // height: 220px;
  // min-width: 20rem;
  max-width: 23rem;
  max-height: 25rem;

  @media screen and (max-width: 1000px) {
    padding: 7rem 8rem;
    gap: 1rem;
  }

  @media screen and (max-width: 800px) {
    padding: 6rem 8rem;
    gap: 1rem;
  }

  .imgContainer {
    // display: flex;
    // align-items: center;
    // justify-content: center;
    width: 10rem;
    height: 10rem;
    text-align: center;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  .textSection {
    font-weight: 600;
    font-size: 2rem;
    font-family: "Work Sans", sans-serif;
    text-align: center;
  }
}
