@import "/src/resources/styles/color.scss";

.main {
  padding: 6rem 6rem;
  margin: 0 auto;
  background-color: $brand-color;

  @media screen and (max-width: 600px) {
    padding: 4rem 2rem;
  }

  .container {
    display: flex;
    justify-content: space-between;

    @media screen and (max-width: 600px) {
      flex-direction: column;
      gap: 2rem;
      text-align: left;
      align-items: flex-start;
    }

    .brandingContainer {
      display: flex;
      flex-direction: column;
      gap: 1.5rem;

      .imgContainer {
        // width: 24rem;
        height: 2em;

        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }

      .socialMediaLinks {
        display: flex;
        gap: 3rem;
        // margin-left: 1.5rem;

        .socialMedia {
          width: 3rem;
          height: 3rem;

          img {
            width: 100%;
            height: 100%;
            object-fit: contain;
          }
        }
      }
    }

    .emailContainer {
      display: flex;
      flex-direction: column;
      gap: 1rem;

      .textContainer {
        font-family: "Work Sans", sans-serif;
        font-weight: 300;
        font-size: 2rem;
        text-align: right;
        color: $white;

        @media screen and (max-width: 600px) {
          text-align: left;
          font-size: 1.5rem;
        }

        &.underline {
          text-decoration: underline;
          cursor: pointer;
        }
      }

      .btnMainContainer {
        margin-top: 1rem;
        text-align: right;

        @media screen and (max-width: 600px) {
          text-align: left;
        }

        .btnContainer {
          padding: 1rem 1rem;
          color: $brand-color;
          font-weight: 700;
          font-size: 1.5rem;
          border-radius: 3rem;

          &:hover {
            color: $white;
          }

          @media screen and (max-width: 600px) {
            font-size: 1.3rem;
          }

          //   @media screen and (max-width: 800px) {
          //     font-size: 4rem;
          //     // text-align: center;
          //   }
        }
      }
    }
  }
}

.dialogContainer {
  // padding: 1rem;
  margin: 2rem;
  display: flex;
  gap: 4rem;

  // @media screen and (max-width: 600px) {
  //   flex-direction: column;
  // }

  .leftContainer {
    display: flex;
    flex-direction: column;
    padding: 2rem;
    border-radius: 15px;
    flex: 1;
    background-color: $brand-color;

    @media screen and (max-width: 600px) {
      gap: 3rem;
    }

    .textContainer {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      gap: 1rem;
      margin-top: auto;

      .title {
        font-size: 1.5rem;
        font-weight: 500;
        color: $white;

        @media screen and (max-width: 400px) {
          font-size: 1.2rem;
        }
      }
    }

    .imgContainer {
      width: 15rem;

      @media screen and (max-width: 400px) {
        width: 20rem;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
  }

  .rightContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 2;
    // gap: 3rem;
    gap: 2rem;
    padding: 1rem 0;

    .title {
      font-size: 2rem;
      color: $brand-color;
      font-weight: 700;
    }

    .formContainer {
      display: flex;
      flex-direction: column;
      margin-top: auto;
      flex: 1;

      @media screen and (max-width: 600px) {
        margin-top: 2rem;
      }

      .formSection {
        display: flex;
        flex-direction: column;
        gap: 3rem;

        @media screen and (max-width: 400px) {
          gap: 1.5rem;
        }

        .flexField {
          display: flex;
          gap: 3rem;
          @media screen and (max-width: 400px) {
            gap: 1.5rem;
          }

          @media screen and (max-width: 600px) {
            flex-direction: column;
          }
        }

        .btnMainContainer {
          margin-top: 1rem;
          display: flex;

          .btnContainer {
            font-size: 1.5rem;
            font-weight: 700;
            padding: 1rem 2.5rem;
          }
        }
      }
    }

    .bottomContainer {
      margin-top: auto;
      display: flex;
      justify-content: space-between;

      @media screen and (max-width: 600px) {
        margin-top: 2rem;
      }

      .textContainer {
        font-family: "Work Sans", sans-serif;
        font-weight: 300;
        font-size: 2rem;
        text-align: right;
        color: $brand-color;

        @media screen and (max-width: 600px) {
          text-align: left;
          font-size: 1.5rem;
        }

        &.underline {
          text-decoration: underline;
          cursor: pointer;
        }
      }

      .socialMedia {
        width: 2rem;
        height: 2rem;

        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
    }
  }

  .closeBtnContainer {
    .closeBtn {
      cursor: pointer;
      font-size: 2rem;
      color: #666666;
    }
  }
}
