@import "/src/resources/styles/color.scss";

.main {
  height: calc(100vh - 7rem);
  background-color: $bg-color;
  padding: 6rem 0;
  display: flex;

  @media screen and (max-width: 1100px) {
    height: max-content;
  }

  .container {
    display: flex;
    flex: 1;
    flex-direction: column;
    gap: 6rem;
    margin: 6rem 5rem 0 5rem;
    align-items: center;

    @media screen and (max-width: 1200px) {
      margin: 0 4rem 0 4rem;
    }

    @media screen and (max-width: 800px) {
      margin: 0 2rem;
    }

    .textContainer {
      display: flex;
      flex-wrap: wrap;
      font-size: 5.5rem;
      font-weight: 700;
      color: $brand-color;
      text-align: center;

      @media screen and (max-width: 1000px) {
        font-size: 5rem;
      }

      @media screen and (max-width: 800px) {
        font-size: 3.2rem;
      }
    }

    .cardGrid {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      // flex-wrap: wrap;
      gap: 7rem;
      flex: 1;
      // align-items: center;

      @media screen and (max-width: 1100px) {
        grid-template-columns: 1fr 1fr;

        justify-items: center;
      }

      @media screen and (max-width: 800px) {
        grid-template-columns: 1fr 1fr;
        justify-items: center;
      }

      @media screen and (max-width: 700px) {
        grid-template-columns: 1fr;
        justify-items: center;
      }
    }

    .bottomText {
      font-family: "Work Sans", sans-serif;
      color: #000;
      font-weight: 400;
      font-size: 3rem;

      @media screen and (max-width: 1000px) {
        font-size: 2.5rem;
      }

      @media screen and (max-width: 800px) {
        text-align: center;
      }
    }
  }
}
