@import "/src/resources/styles/color.scss";

.brandsSection {
  //   height: calc(100vh - 7rem);
  height: max-content;
  background-color: $bg-color;
  display: flex;
  flex-direction: column;
  padding: 4.5rem 0;
  gap: 4.5rem;

  .topContainer {
    display: flex;
    justify-content: center;

    .title {
      text-align: center;
      font-family: Quicksand;
      font-size: 4.375rem;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      color: $brand-color;
    }
  }

  .brandCarouselContainer {
    width: 100%;

    // .customSwiperSlide {
    //   @media screen and (max-width: 850px) {
    //     display: flex;
    //     justify-content: center;
    //   }
    // }

    .brandCardRootContainer {
      display: flex;
      padding: 1.5rem 3rem;
      border-radius: 2rem;
      background-color: $white;
      height: 11.4375rem;
      width: 18rem;
      justify-content: center;
      align-items: center;

      @media screen and (max-width: 850px) {
        max-width: 20rem;
      }

      .imgContainer {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;

        img {
          object-fit: contain;
        }
      }
    }
  }
}
